import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, query, collection, getDocs, where } from 'firebase/firestore';
import { auth, db } from '../../../firebase-login';
import { HeaderMenuERP } from '../../components/HeaderMenu_ERP';

export const SignUpModal = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [fullName, setFullName] = useState('');
    const [position, setPosition] = useState('');
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [companyphoneNumber, setCompanyPhoneNumber] = useState('');
    const [department, setDepartment] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [joinDate, setJoinDate] = useState('');
    const [error, setError] = useState('');

    const phoneRegex = /^010-\d{4}-\d{4}$/;

    // 이메일 도메인 중복 검사
    const checkDomainExists = async (emailDomain) => {
        const q = query(collection(db, "users"), where("domain", "==", emailDomain));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
    };

    const handleSignUp = async (event) => {
        event.preventDefault();
        const emailDomain = email.substring(email.lastIndexOf("@") + 1);

        if (!phoneRegex.test(phoneNumber)) {
            setError('전화번호 형식이 올바르지 않습니다. 010-1234-5678 형식으로 입력해주세요.');
            return;
        }

        const domainExists = await checkDomainExists(emailDomain);
        if (domainExists) {
            setError("이 도메인은 이미 사용 중입니다.");
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            await setDoc(doc(db, "users", userCredential.user.email), {
                companyName, email, fullName,companyphoneNumber ,address,phoneNumber, position, department, birthDate, joinDate, domain: emailDomain,
            });

            console.log("회원가입 성공");
            onClose();
        } catch (error) {
            setError(error.code === "auth/invalid-email" ? "가입 불가능한 도메인입니다." : error.message);
        }
    };

    const closeModal = (e) => {
        if (e.target.id === "modalBackdrop") {
            onClose();
        }
        if (e.currentTarget === e.target) {
            onClose();
      }
    };

    return (
        <div className='ModalBackdrop' onClick={closeModal}>
            <div className="ERP-SignupModal">
                <h2>회원가입</h2>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <form onSubmit={handleSignUp}>
                    <div>
                        <label>본인 이름:</label>
                        <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} required />
                    </div>
                    <div>
                        <label>주소:</label>
                        <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} required />
                    </div>
                    <div>
                        <label>생년월일:</label>
                        <input type="date" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} required />
                    </div>
                    <div>
                        <label>전화번호:</label>
                        <input type="text" placeholder='ex)010-1111-1111' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                    </div>
                    <div>
                        <label>회사 이름:</label>
                        <input type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required />
                    </div>
                    <div>
                        <label>아이디:</label>
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div>
                        <label>비밀번호:</label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                    <div>
                        <label>직급:</label>
                        <input type="text" value={position} onChange={(e) => setPosition(e.target.value)} required />
                    </div>
                    <div>
                        <label>부서:</label>
                        <input type="text" value={department} onChange={(e) => setDepartment(e.target.value)} required />
                    </div>
                    <div>
                        <label>사내번호:</label>
                        <input type="text"  value={companyphoneNumber} onChange={(e) => setCompanyPhoneNumber(e.target.value)} required />
                    </div>
                    <div>
                        <label>입사일:</label>
                        <input type="date" value={joinDate} onChange={(e) => setJoinDate(e.target.value)} required />
                    </div>
                    <button type="submit">회원가입</button>
                    <button type="button" onClick={onClose}>닫기</button>
                </form>
            </div>
        </div>
    );
};
