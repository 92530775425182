import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase-login';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { HeaderMenuERP } from "../../components/HeaderMenu_ERP";
import '../../css/Contacts.css'; // 연락처에 맞는 CSS 스타일링
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export const Contacts = () => {
    const [contacts, setContacts] = useState([]);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const auth = getAuth();
    const navigate = useNavigate();
    
    useEffect(() => {
        //Auth 상태 감지 리스너
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                navigate('/erp/login');
            }
        });
  
        return () => unsubscribe();
    }, [auth, navigate]);
  
    useEffect(() => {
        const fetchContacts = async () => {
            const userEmail = sessionStorage.getItem('email');
            const domain = userEmail.split('@')[1];
            const q = query(collection(db, 'users'), where('domain', '==', domain));

            try {
                const querySnapshot = await getDocs(q);
                setContacts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            } catch (error) {
                console.error('Error fetching contacts:', error);
            }
        };

        fetchContacts();
    }, []);

    const toggleContactDetails = (contactId) => {
        setSelectedContactId(selectedContactId === contactId ? null : contactId);
    };

    return (
        <div className="ERP-Wrap">
            <HeaderMenuERP />
            <div className="contacts-container">
                <h2>연락처</h2>
                <div className="contacts-list">
                    {contacts.map((contact) => (
                        <div key={contact.id} className="contact-item" onClick={() => toggleContactDetails(contact.id)}>
                            <p><strong>이름:</strong> {contact.fullName}</p>
                            <p><strong>부서:</strong> {contact.department}</p>
                            <p><strong>직급:</strong> {contact.position}</p>
                            {selectedContactId === contact.id && (
                                <div className="contact-details">
                                    <p><strong>메일:</strong> {contact.email}@{contact.domain}</p>
                                    <p><strong>전화번호:</strong> {contact.phoneNumber}</p>
                                    <p><strong>주소:</strong> {contact.address}</p>
                                    <p><strong>사내번호:</strong> {contact.companyphoneNumber}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
