import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, getAuth, updatePassword } from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../../../firebase-login'; // Firebase 설정 경로에 맞게 조정하세요
import { HeaderMenuERP } from '../../components/HeaderMenu_ERP';
import '../../css/ERPhrm.css'

export const HRM = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [position, setPosition] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyphoneNumber, setCompanyPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [department, setDepartment] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [joinDate, setJoinDate] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [editing, setEditing] = useState(false);
  const [currentUserDomain, setCurrentUserDomain] = useState('');

  const phoneRegex = /^010-\d{4}-\d{4}$/;

  useEffect(() => {
    const emailFromSession = sessionStorage.getItem('email');
    const domain = emailFromSession?.split('@')[1];
    setCurrentUserDomain(domain);
    fetchEmployees();

  }, []);

  const fetchEmployees = async () => {
    // 세션스토리지에서 currentUserDomain 가져오기
    const emailFromSession = sessionStorage.getItem('email');
    const currentUserDomain = emailFromSession?.split('@')[1];

    if (!currentUserDomain) {
      console.error('Domain 정보를 찾을 수 없습니다.');
      return;
    }

    const q = query(collection(db, 'users'), where('domain', '==', currentUserDomain));

    try {
      const employeesSnapshot = await getDocs(q);
      const employeeList = employeesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEmployees(employeeList);
    } catch (error) {
      console.error('직원 목록을 불러오는 중 오류 발생:', error);
    }
  };


  const handleEditEmployee = (employee) => {
    setSelectedEmployee(employee);
    setEditing(true);
    setShowModal(true);
    setEmail(employee.email); // 이메일
    setFullName(employee.fullName); // 이름
    setPosition(employee.position); // 직급
    setPhoneNumber(employee.phoneNumber); // 전화번호
    setCompanyPhoneNumber(employee.companyphoneNumber); // 사내번호
    setAddress(employee.address); // 사내번호
    setDepartment(employee.department); // 부서
    setBirthDate(employee.birthDate); // 생년월일
    setJoinDate(employee.joinDate); // 입사일

  };

  //인풋 초기화용임
  const resetInputFields = () => {
    setEmail('');
    setPassword('');
    setFullName('');
    setPhoneNumber('');
    setCompanyPhoneNumber('');
    setAddress('');
    setPosition('');
    setDepartment('');
    setBirthDate('');
    setJoinDate('');
    setSelectedEmployee(null);
    setError('');
    setEditing(false);
  };

  const handleUpdateEmployee = async (event) => {
    event.preventDefault();
    const { id } = selectedEmployee;

    if (!phoneNumber.match(phoneRegex)) {
      setError('전화번호 형식이 올바르지 않습니다. 010-1234-5678 형식으로 입력해주세요.');
      return;
    }

    try {
      await updateDoc(doc(db, "employees", id), {
        // Update employee data
        email: email,
        fullName: fullName,
        phoneNumber: phoneNumber,
        companyphoneNumber: companyphoneNumber,
        address: address,
        position: position,
        department: department,
        birthDate: birthDate,
        joinDate: joinDate,
        domain: currentUserDomain,
      });

      if (password) {
        const user = await auth.getUserByEmail(email);
        await updatePassword(user, password);
      }

      setShowModal(false); // 모달 닫기
      setError('');
      setEditing(false);
      fetchEmployees();
    } catch (error) {
      setError(error.message);
    }
  };

  const closeModal = (e) => {
    if (e.target.id === "modalBackdrop") {
      setShowModal(false);
      resetInputFields();
    }
    if (e.currentTarget === e.target) {
      setShowModal(false);
      resetInputFields();
    }
  };
  const handleAddEmployee = async (event) => {
    event.preventDefault();
    const fullEmail = `${email}@${currentUserDomain}`;

    if (!phoneNumber.match(phoneRegex)) {
      setError('전화번호 형식이 올바르지 않습니다. 010-1234-5678 형식으로 입력해주세요.');
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, fullEmail, password);
      await setDoc(doc(db, "users", fullEmail), {
        companyName: sessionStorage.getItem('companyName'),
        email,
        fullName,
        phoneNumber,
        companyphoneNumber,
        address,
        position,
        department,
        birthDate,
        joinDate,
        domain: currentUserDomain,
      });

      setShowModal(false); // 모달 닫기
      setError('');
      setEditing(false);
      fetchEmployees();
      resetInputFields();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleAddButtonClick = () => {
    resetInputFields();
    setEditing(false);
    setShowModal(true);
  };


  return (
    <div className="ERP-Wrap">
      <HeaderMenuERP />

      <div className="hrm-Container">
        <ul className='hrm-sidebar'>
          {employees.map(employee => (
            <li key={employee.id} onClick={() => handleEditEmployee(employee)}>
              {employee.fullName}
            </li>
          ))}
          <button onClick={handleAddButtonClick}>직원 추가</button>
        </ul>

        {showModal && (
          <div className="ModalBackdrop" onClick={closeModal}>
            <form onSubmit={editing ? handleUpdateEmployee : handleAddEmployee} className='Modal' onClick={e => e.stopPropagation()}>
              <h2>{editing ? '직원 수정' : '직원 추가'}</h2>
              <div>
                <label>아이디:</label>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={editing}
                />
                <span>@{currentUserDomain}</span>
              </div>

              <div>
                <label>비밀번호:</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required={!editing}
                />
              </div>
              <div>
                <label>이름:</label>
                <input
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>주소:</label>
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>직급:</label>
                <input
                  type="text"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>전화번호:</label>
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>사내번호:</label>
                <input
                  type="text"
                  value={companyphoneNumber}
                  onChange={(e) => setCompanyPhoneNumber(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>부서:</label>
                <input
                  type="text"
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>생년월일:</label>
                <input
                  type="date"
                  value={birthDate}
                  onChange={(e) => setBirthDate(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>입사일:</label>
                <input
                  type="date"
                  value={joinDate}
                  onChange={(e) => setJoinDate(e.target.value)}
                  required
                />
              </div>
              <div className='btnWrap'>
                <button type="submit">{editing ? '직원 수정' : '직원 추가'}</button>
                <button type="button" onClick={() => setShowModal(false)}>닫기</button>
              </div>
              {error && <p style={{ color: 'red' }}>{error}</p>}
            </form>
          </div>
        )}

      </div>
    </div>
  );
};
