// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { useState,useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB8HGEEpm3dOV3ngaxkD0LULKoq5q8dwO4",
  authDomain: "pofol001-5ed52.firebaseapp.com",
  projectId: "pofol001-5ed52",
  storageBucket: "pofol001-5ed52.appspot.com",
  messagingSenderId: "705217139095",
  appId: "1:705217139095:web:b780f576516b5b30bd34d2",
  measurementId: "G-V80K8RLFWB"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);


export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user); // user 객체가 있으면 true, 없으면 false로 설정
    });

    return () => unsubscribe(); // 컴포넌트 언마운트 시 구독 취소
  }, []);

  return isAuthenticated;
};

