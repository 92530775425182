import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase-login'; 
import { collection, query, where, addDoc, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { HeaderMenuERP } from "../../components/HeaderMenu_ERP"; 
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import '../../css/Deals.css'; 

export const Deals = () => {
  const [deals, setDeals] = useState([]);
  const [newDeal, setNewDeal] = useState({ companyName: '', name: '', phone: '', address: '', note: '' });
  const [editingDeal, setEditingDeal] = useState(null);
  const [userDomain, setUserDomain] = useState('');
  
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    //Auth 상태 감지 리스너
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/erp/login');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  useEffect(() => {
    const userEmail = sessionStorage.getItem('email');
    if (userEmail) {
      const domain = userEmail.split('@')[1];
      setUserDomain(domain);
      fetchDeals(domain);
    }
  }, []);

  const fetchDeals = async (domain) => {
    const dealsRef = collection(db, "domains", domain, "deals");
    const querySnapshot = await getDocs(dealsRef);
    const fetchedDeals = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setDeals(fetchedDeals);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDeal({ ...newDeal, [name]: value });
  };

  const handleAddDeal = async () => {
    const dealsRef = collection(db, "domains", userDomain, "deals");
    await addDoc(dealsRef, { ...newDeal });
    fetchDeals(userDomain);
    setNewDeal({ companyName: '', name: '', phone: '', address: '', note: '' });
  };

  const handleEditDeal = (deal) => {
    setEditingDeal(deal);
  };

  const handleUpdateDeal = async () => {
    const dealRef = doc(db, "domains", userDomain, "deals", editingDeal.id);
    await updateDoc(dealRef, { ...editingDeal });
    fetchDeals(userDomain);
    setEditingDeal(null);
  };

  const handleDeleteDeal = async (dealId) => {
    await deleteDoc(doc(db, "domains", userDomain, "deals", dealId));
    fetchDeals(userDomain);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingDeal({ ...editingDeal, [name]: value });
  };

  return (
    <div className="ERP-Wrap">
      <HeaderMenuERP />
      <div className="deals-container">
        <h2>거래처 관리</h2>
        <div className="deal-form">
          <input name="companyName" placeholder="회사명" value={newDeal.companyName} onChange={handleInputChange} />
          <input name="name" placeholder="이름" value={newDeal.name} onChange={handleInputChange} />
          <input name="phone" placeholder="전화번호" value={newDeal.phone} onChange={handleInputChange} />
          <input name="address" placeholder="주소" value={newDeal.address} onChange={handleInputChange} />
          <input name="note" placeholder="비고" value={newDeal.note} onChange={handleInputChange} />
          <button onClick={handleAddDeal}>거래처 추가</button>
        </div>
        <div className="deal-list">
          {deals.map((deal) => (
            <div key={deal.id} className="deal-item">
              {editingDeal && editingDeal.id === deal.id ? (
                <>
                  <input name="companyName" value={editingDeal.companyName} onChange={handleEditInputChange} />
                  <input name="name" value={editingDeal.name} onChange={handleEditInputChange} />
                  <input name="phone" value={editingDeal.phone} onChange={handleEditInputChange} />
                  <input name="address" value={editingDeal.address} onChange={handleEditInputChange} />
                  <input name="note" value={editingDeal.note} onChange={handleEditInputChange} />
                  <button onClick={handleUpdateDeal}>저장</button>
                  <button onClick={() => setEditingDeal(null)}>취소</button>
                </>
              ) : (
                <>
                  <span>{deal.companyName}</span>
                  <span>{deal.name}</span>
                  <span>{deal.phone}</span>
                  <span>{deal.address}</span>
                  <span>{deal.note}</span>
                  <button onClick={() => handleEditDeal(deal)}>수정</button>
                  <button onClick={() => handleDeleteDeal(deal.id)}>삭제</button>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};