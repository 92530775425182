import { initializeApp, getApps } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB8HGEEpm3dOV3ngaxkD0LULKoq5q8dwO4",
  authDomain: "pofol001-5ed52.firebaseapp.com",
  projectId: "pofol001-5ed52",
  storageBucket: "pofol001-5ed52.appspot.com",
  messagingSenderId: "705217139095",
  appId: "1:705217139095:web:b780f576516b5b30bd34d2",
  measurementId: "G-V80K8RLFWB"
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];

const db = getFirestore(app);
export { db, app };