import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc, query, where } from 'firebase/firestore';

export const TicMaster = () => {
  const [performances, setPerformances] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('classic');
  const [form, setForm] = useState({
    title: '',
    description: '',
    category: '',
    imageUrl: '',
    schedules: []
  });
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchPerformances(selectedCategory);
  }, [selectedCategory]);

  const fetchPerformances = async (category) => {
    try {
      const q = query(collection(db, 'performances'), where('category', '==', category));
      const querySnapshot = await getDocs(q);
      const fetchedPerformances = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPerformances(fetchedPerformances);
    } catch (error) {
      console.error("Error fetching performances: ", error);
      setPerformances([]);
    }
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    fetchPerformances(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleScheduleChange = (index, field, value) => {
    const newSchedules = [...form.schedules];
    newSchedules[index] = { ...newSchedules[index], [field]: value };
    setForm({ ...form, schedules: newSchedules });
  };

  const handleAddSchedule = () => {
    setForm({ ...form, schedules: [...form.schedules, { date: '', location: '',  seats: [] }] });
  };

  const handleRemoveSchedule = (index) => {
    const newSchedules = [...form.schedules];
    newSchedules.splice(index, 1);
    setForm({ ...form, schedules: newSchedules });
  };

  const handleSeatChange = (scheduleIndex, seatIndex, field, value) => {
    const updatedSchedules = [...form.schedules];
    updatedSchedules[scheduleIndex].seats[seatIndex] = { ...updatedSchedules[scheduleIndex].seats[seatIndex], [field]: value };
    setForm({ ...form, schedules: updatedSchedules });
  };

  const handleAddSeat = (scheduleIndex) => {
    const updatedSchedules = [...form.schedules];
    updatedSchedules[scheduleIndex].seats.push({ number: '', status: 'available' });
    setForm({ ...form, schedules: updatedSchedules });
  };

  const handleRemoveSeat = (scheduleIndex, seatIndex) => {
    const updatedSchedules = [...form.schedules];
    updatedSchedules[scheduleIndex].seats.splice(seatIndex, 1);
    setForm({ ...form, schedules: updatedSchedules });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const performanceData = { ...form, category: selectedCategory };
    if (editingId) {
      await updateDoc(doc(db, 'performances', editingId), performanceData);
    } else {
      await addDoc(collection(db, 'performances'), performanceData);
    }
    setForm({
      title: '',
      description: '',
      category: '',
      imageUrl:'',
      schedules: []
    });
    setEditingId(null);
    fetchPerformances(selectedCategory);
  };

  const handleDeletePerformance = async (id) => {
    await deleteDoc(doc(db, 'performances', id));
    fetchPerformances(selectedCategory);
  };

  const handleEditClick = (performance) => {
    setEditingId(performance.id);
    setForm(performance);
  };


  
  return (
    <div className='TicketMaster'>
      <select value={selectedCategory} onChange={handleCategoryChange}>
        <option value="classic">클래식</option>
        <option value="concert">콘서트</option>
        <option value="exhibition">전시</option>
        <option value="musical">뮤지컬</option>
        <option value="play">연극</option>
        <option value="sports">스포츠</option>
      </select>

      <form onSubmit={handleSubmit}>
        <input type="text" name="title" value={form.title} onChange={handleInputChange} placeholder="공연 제목" />
        <input type="text" name="description" value={form.description} onChange={handleInputChange} placeholder="설명" />
        <input type="text" name='imageUrl' value={form.imageUrl} onChange={handleInputChange} placeholder="이미지 URL" />
        
        {form.schedules.map((schedule, scheduleIndex) => (
          <div key={scheduleIndex}>
            <input type="datetime-local" value={schedule.date} onChange={(e) => handleScheduleChange(scheduleIndex, 'date', e.target.value)} />
            <input type="text" value={schedule.location} onChange={(e) => handleScheduleChange(scheduleIndex, 'location', e.target.value)} placeholder="위치" />
            
            {schedule.seats.map((seat, seatIndex) => (
              <div key={seatIndex}>
                <input type="text" value={seat.number} onChange={(e) => handleSeatChange(scheduleIndex, seatIndex, 'number', e.target.value)} placeholder="좌석 번호" />
                <select value={seat.status} onChange={(e) => handleSeatChange(scheduleIndex, seatIndex, 'status', e.target.value)}>
                  <option value="available">가능</option>
                  <option value="reserved">예약됨</option>
                </select>
                <button type="button" onClick={() => handleRemoveSeat(scheduleIndex, seatIndex)}>좌석 삭제</button>
              </div>
            ))}
            <button type="button" onClick={() => handleAddSeat(scheduleIndex)}>좌석 추가</button>
            <button type="button" onClick={() => handleRemoveSchedule(scheduleIndex)}>일정 삭제</button>
          </div>
        ))}
        <div className='btn3s'>
          <button type="button" onClick={handleAddSchedule}>일정 추가</button>
          <button type="submit">{editingId ? '수정' : '추가'}</button>
          {editingId && <button type="button" onClick={() => setEditingId(null)}>취소</button>}
        </div>
      </form>

      <div className='TicketLists'>
        {performances.map((perf) => (
          <div key={perf.id}>
            <img src={perf.imageUrl} alt="공연 이미지" style={{ width: '100px', height: '100px' }} />
            <h3>{perf.title}</h3>
            <p>{perf.description}</p>
            {perf.schedules.map((schedule, index) => (
              <div key={index}>
                <p>{schedule.date}</p>
                <p>{schedule.location}</p>
                <div>
                  {schedule.seats.map((seat, seatIndex) => (
                    <span key={seatIndex}>{`${seat.number} (${seat.status})`}</span>
                  ))}
                </div>
              </div>
            ))}
            <button onClick={() => handleEditClick(perf)}>수정</button>
            <button onClick={() => handleDeletePerformance(perf.id)}>삭제</button>
          </div>
        ))}
      </div>
    </div>
  );
};