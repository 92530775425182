import React, { useState, useEffect } from "react";
import { getDocs, collection, addDoc, deleteDoc, doc, updateDoc, query, onSnapshot } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { subDays, subWeeks, subMonths, subYears, format, parseISO, endOfQuarter, addQuarters, getYear, eachQuarterOfInterval, startOfMonth, endOfMonth } from 'date-fns';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import '../css/Am.css'

export const RecordTransactions = () => {
    const [filterText, setFilterText] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [newTransaction, setNewTransaction] = useState({
        date: '',
        type: '',
        amount: '',
        client: '',
        note: ''
    });
    const [typeFilter, setTypeFilter] = useState('all');
    const [filterPeriod, setFilterPeriod] = useState('all');
    const [isEditing, setIsEditing] = useState(null);
    const [editTransaction, setEditTransaction] = useState({
        date: '',
        type: '',
        amount: '',
        client: '',
        note: ''
    });

    useEffect(() => {
        const loadTransactions = async () => {
            const querySnapshot = await getDocs(collection(db, "transactions"));
            const loadedTransactions = querySnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .sort((a, b) => new Date(a.date) - new Date(b.date)); // 날짜 오름차순 정렬
            setTransactions(loadedTransactions);
        };
        loadTransactions();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewTransaction(prevState => ({ ...prevState, [name]: value }));
    };

    const handleFilterChange = (e) => {
        setFilterPeriod(e.target.value);
    };

    const handleTypeFilterChange = (e) => {
        setTypeFilter(e.target.value);
    };

    const handleAddTransaction = async (e) => {
        e.preventDefault();
        try {
            const docRef = await addDoc(collection(db, "transactions"), newTransaction);
            setTransactions([...transactions, { ...newTransaction, id: docRef.id }]);
            setNewTransaction({ date: '', type: '', amount: '', client: '', note: '' });
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    const handleDeleteTransaction = async (id) => {
        try {
            await deleteDoc(doc(db, "transactions", id));
            setTransactions(transactions.filter(transaction => transaction.id !== id));
        } catch (error) {
            console.error("Error removing document: ", error);
        }
    };

    const startEdit = (transaction) => {
        setIsEditing(transaction.id);
        setEditTransaction({ ...transaction });
    };

    const cancelEdit = () => {
        setIsEditing(null);
        setEditTransaction({ date: '', type: '', amount: '', client: '', note: '' });
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditTransaction(prevState => ({ ...prevState, [name]: value }));
    };

    const saveEdit = async () => {
        try {
            const transactionRef = doc(db, "transactions", isEditing);
            await updateDoc(transactionRef, editTransaction);
            setTransactions(transactions.map(t => (t.id === isEditing ? { ...t, ...editTransaction } : t)));
            cancelEdit();
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

    const parseDateString = (dateString) => parseISO(dateString);

    const filteredTransactions = transactions.filter(transaction => {
        const matchFilterText = transaction.client.toLowerCase().includes(filterText.toLowerCase()) ||
            transaction.note.toLowerCase().includes(filterText.toLowerCase());
        const matchTypeFilter = typeFilter === 'all' || transaction.type === typeFilter;

        const transactionDate = parseDateString(transaction.date);
        const today = new Date();
        let matchPeriodFilter = true;
        switch (filterPeriod) {
            case '1d': matchPeriodFilter = transactionDate >= subDays(today, 1); break;
            case '1w': matchPeriodFilter = transactionDate >= subWeeks(today, 1); break;
            case '1m': matchPeriodFilter = transactionDate >= subMonths(today, 1); break;
            case '3m': matchPeriodFilter = transactionDate >= subMonths(today, 3); break;
            case '6m': matchPeriodFilter = transactionDate >= subMonths(today, 6); break;
            case '1y': matchPeriodFilter = transactionDate >= subYears(today, 1); break;
        }

        return matchFilterText && matchTypeFilter && matchPeriodFilter;
    });

    const calculateTotals = () => {
        const totals = filteredTransactions.reduce((acc, transaction) => {
            if (transaction.type === '수입') {
                acc.income += parseFloat(transaction.amount);
            } else if (transaction.type === '지출') {
                acc.expense += parseFloat(transaction.amount);
            }
            return acc;
        }, { income: 0, expense: 0 });

        totals.net = totals.income - totals.expense;

        return totals;
    };

    const { income, expense, net } = calculateTotals();

    return (
        <div className="tradeRecords">
            <h2>거래 기록 관리</h2>
            <form onSubmit={handleAddTransaction} className="tradeForm">
                <input name="date" type="date" value={newTransaction.date} onChange={handleChange} required />
                <select name="type" value={newTransaction.type} onChange={handleChange} required>
                    <option value="">거래 유형 선택</option>
                    <option value="수입">수입</option>
                    <option value="지출">지출</option>
                </select>
                <input name="amount" type="number" value={newTransaction.amount} onChange={handleChange} placeholder="금액" required />
                <input name="client" type="text" value={newTransaction.client} onChange={handleChange} placeholder="거래처" required />
                <input name="note" type="text" value={newTransaction.note} onChange={handleChange} placeholder="비고" required />
                <button type="submit">거래 추가</button>
            </form>

            <div className="search-filter-wrap">
                <input
                    type="text"
                    placeholder="검색... (거래처, 비고)"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
                <select value={filterPeriod} onChange={handleFilterChange}>
                    <option value="all">전체</option>
                    <option value="1d">1일</option>
                    <option value="1w">1주일</option>
                    <option value="1m">1개월</option>
                    <option value="3m">3개월</option>
                    <option value="6m">6개월</option>
                    <option value="1y">1년</option>
                </select>
                <select value={typeFilter} onChange={handleTypeFilterChange}>
                    <option value="all">모든 거래</option>
                    <option value="수입">수입</option>
                    <option value="지출">지출</option>
                </select>
            </div>

            <div className="totals">
                <div>수입 총계: {income.toLocaleString()}원</div>
                <div>지출 총계: {expense.toLocaleString()}원</div>
                <div>순수익: {net.toLocaleString()}원</div>
            </div>

            <ul className="tranction-container">
                {filteredTransactions.map(transaction => (
                    <li key={transaction.id} className="transaction-wrap">
                        {isEditing === transaction.id ? (
                            <>
                                <input name="date" type="date" value={editTransaction.date} onChange={handleEditChange} />
                                <select name="type" value={editTransaction.type} onChange={handleEditChange}>
                                    <option value="수입">수입</option>
                                    <option value="지출">지출</option>
                                </select>
                                <input name="amount" type="number" value={editTransaction.amount} onChange={handleEditChange} />
                                <input name="client" type="text" value={editTransaction.client} onChange={handleEditChange} />
                                <input name="note" type="text" value={editTransaction.note} onChange={handleEditChange} />
                                <div>
                                    <button onClick={saveEdit}>저장</button>
                                    <button onClick={cancelEdit}>취소</button>
                                </div>
                            </>
                        ) : (
                            <>
                                {transaction.date} - {transaction.type} -
                                {parseFloat(transaction.amount).toLocaleString()}원 - {transaction.client} - {transaction.note}
                                <div>
                                    <button onClick={() => startEdit(transaction)}>수정</button>
                                    <button onClick={() => handleDeleteTransaction(transaction.id)}>삭제</button>
                                </div>
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}
export const VisualizeFinancialData = () => {
    const [period, setPeriod] = useState('all');
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        const q = query(collection(db, "transactions"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newTransactions = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setTransactions(newTransactions);
        });
        return () => unsubscribe();
    }, []);

    const filterTransactionsByPeriod = (transactions, period) => {
        const now = new Date();
        const filterStartDate = {
            '1d': subDays(now, 1),
            '1w': subWeeks(now, 1),
            '1m': subMonths(now, 1),
            '3m': subMonths(now, 3),
            '6m': subMonths(now, 6),
            '1y': subYears(now, 1),
        }[period] || null;

        if (!filterStartDate) return transactions;

        return transactions.filter(transaction => parseISO(transaction.date) >= filterStartDate);
    };

    const filteredTransactions = filterTransactionsByPeriod(transactions, period);

    const dataMap = filteredTransactions.reduce((acc, { date, type, amount }) => {
        const month = format(startOfMonth(parseISO(date)), 'yyyy-MM');
        if (!acc[month]) {
            acc[month] = { month, income: 0, expense: 0, net: 0 };
        }
        const amt = parseFloat(amount);
        if (type === '수입') {
            acc[month].income += amt;
        } else if (type === '지출') {
            acc[month].expense += amt;
        }
        acc[month].net = acc[month].income - acc[month].expense;
        return acc;
    }, {});

    const data = Object.values(dataMap).sort((a, b) => new Date(a.month) - new Date(b.month));

    const cumulativeData = data.reduce((acc, item, index) => {
        const previousNet = index === 0 ? 0 : acc[index - 1].cumulativeNet;
        const netValue = typeof item.net === 'number' ? item.net : 0;
        const newCumulativeNet = previousNet + netValue;
        acc.push({ ...item, cumulativeNet: newCumulativeNet });
        return acc;
    }, []);

    return (
        <div className="VisualizeFinancialDataWrap">
            <div>
                <h2>재무 데이터 시각화</h2>
                <select value={period} onChange={(e) => setPeriod(e.target.value)}>
                    <option value="all">전체</option>
                    <option value="1d">1일</option>
                    <option value="1w">1주일</option>
                    <option value="1m">1개월</option>
                    <option value="3m">3개월</option>
                    <option value="6m">6개월</option>
                    <option value="1y">1년</option>
                </select>
            </div>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={cumulativeData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="income" stroke="#82ca9d" name="수입" />
                    <Line type="monotone" dataKey="expense" stroke="#8884d8" name="지출" />
                    <Line type="monotone" dataKey="cumulativeNet" stroke="#ff7300" name="누적 순수익" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export const GenerateFinancialReports = () => {
    const [transactions, setTransactions] = useState([]);
    const [selectedYear, setSelectedYear] = useState(getYear(new Date()));
    const [availableYears, setAvailableYears] = useState([]);
    useEffect(() => {
        const q = query(collection(db, "transactions"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const transactionsData = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                date: doc.data().date,
                amount: parseFloat(doc.data().amount),
            }));
            setTransactions(transactionsData);

            const years = transactionsData.map(t => getYear(parseISO(t.date)));
            const minYear = Math.min(...years);
            const maxYear = Math.max(...years);
            const yearRange = Array.from({ length: maxYear - minYear + 1 }, (_, i) => minYear + i);
            setAvailableYears(yearRange);
        });

        return () => unsubscribe();
    }, []);

    const calculateFinancialDataForQuarter = (startDate, endDate) => {
        const filteredTransactions = transactions.filter(t => {
            const date = parseISO(t.date);
            return date >= startDate && date <= endDate;
        });

        const summary = filteredTransactions.reduce((acc, curr) => {
            if (curr.type === '수입') acc.income += curr.amount;
            else if (curr.type === '지출') acc.expense += curr.amount;
            return acc;
        }, { income: 0, expense: 0, netIncome: 0 });

        summary.netIncome = summary.income - summary.expense;
        return summary;
    };

    const getPreviousQuarterData = (currentQuarterStart) => {
        const prevQuarterStart = addQuarters(currentQuarterStart, -1);
        const prevQuarterEnd = endOfQuarter(prevQuarterStart);
        return calculateFinancialDataForQuarter(prevQuarterStart, prevQuarterEnd);
    };

    const calculateGrowthRate = (current, previous) => {
        if (previous === 0 || isNaN(previous)) {
            return "-";
        }
        return (((current - previous) / previous) * 100).toFixed(2);
    };

    const renderFinancialReportsForYear = () => {
        const startOfYear = new Date(selectedYear, 0, 1);
        const endOfYear = new Date(selectedYear, 11, 31);
        const quarters = eachQuarterOfInterval({ start: startOfYear, end: endOfYear });

        return quarters.map((quarterStart, index) => {
            const quarterEnd = endOfQuarter(quarterStart);
            const financialData = calculateFinancialDataForQuarter(quarterStart, quarterEnd);
            const prevFinancialData = getPreviousQuarterData(quarterStart);

            const incomeGrowth = calculateGrowthRate(financialData.income, prevFinancialData.income);
            const expenseGrowth = calculateGrowthRate(financialData.expense, prevFinancialData.expense);
            const netIncomeGrowth = calculateGrowthRate(financialData.netIncome, prevFinancialData.netIncome);

            return (
                <div key={index}>
                    <h3>{`${selectedYear}년 Q${index + 1}`}</h3>
                    <p>수입: {financialData.income.toLocaleString()}원 ({index === 0 ? "작년 4" : `${index}`}분기 대비 증감률: {incomeGrowth}%)</p>
                    <p>지출: {financialData.expense.toLocaleString()}원 ({index === 0 ? "작년 4" : `${index}`}분기 대비 증감률: {expenseGrowth}%)</p>
                    <p>순이익: {financialData.netIncome.toLocaleString()}원 ({index === 0 ? "작년 4" : `${index}`}분기 대비 증감률: {netIncomeGrowth}%)</p>
                    <ResponsiveContainer width="100%" height={250}>
                        <BarChart
                            data={[
                                { name: '재무', 수입: financialData.income, 지출: financialData.expense, 순이익: financialData.netIncome }
                            ]}
                            margin={{
                                top: 20, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis tickFormatter={(value) => value.toLocaleString()} />
                            <Tooltip formatter={(value) => value.toLocaleString()} />
                            <Legend />
                            <Bar dataKey="수입" fill="#8884d8" name="수입" />
                            <Bar dataKey="지출" fill="#82ca9d" name="지출" />
                            <Bar dataKey="순이익" fill="#ffc658" name="순이익" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            );
        });
    };

    return (
        <div className="FinancialReportsWrap">
            <h2>분기별 재무 보고서</h2>
            <select value={selectedYear} onChange={e => setSelectedYear(Number(e.target.value))}>
                {availableYears.map(year => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
            {renderFinancialReportsForYear()}
        </div>
    );
};