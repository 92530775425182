import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import BarChart from "../components/BarChart";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import '../css/MainPage.css';
import HeaderMenu from "../components/HeaderMenu";

export const Main = ({ setIsHeaderVisible }) => {
  const [isTest001Visible, setIsTest001Visible] = useState(true);
  const scrollRef = useRef(null);

  useEffect(() => {
    const introSeen = localStorage.getItem('introSeen');
    if (introSeen === 'true') {
      setIsTest001Visible(false);
    }
  }, []);

  const handleScroll = () => {
    if (scrollRef.current) {
      const position = scrollRef.current.scrollTop;
      if (position > 2000 && isTest001Visible) {
        setIsTest001Visible(false);
        localStorage.setItem('introSeen', 'true');
        setIsHeaderVisible(true);
      }
    }
  };

  useEffect(() => {
    const scrollableElement = scrollRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isTest001Visible]);

  useEffect(() => {
    if (isTest001Visible) {
      document.body.style.overflowX = 'hidden';
      document.body.style.overflowY = 'hidden';
      document.body.classList.add('scroll-event-active');
      setIsHeaderVisible(false);
    } else {
      document.body.style.overflowX = 'hidden';
      document.body.style.overflowY = 'auto';
      document.body.classList.remove('scroll-event-active');
      setIsHeaderVisible(true);
    }
  }, [isTest001Visible, setIsHeaderVisible]);

  return (
    <div className={isTest001Visible ? "scroll" : "noScroll"} ref={scrollRef}>
      {isTest001Visible && (
        <div className="ScrollEvent">
          <div className="introSection">
            <h1>안녕하세요, <span className="highlight">김선익</span>입니다.</h1>
            <div className="subs">
              <h2>열정과 도전</h2>
              <p>"새로운 기술에 대한 끝없는 호기심과 열정으로 항상 다음 도전을 준비합니다. 변화하는 소프트웨어 개발 세계에서 매일 성장하고, 문제 해결에 큰 기쁨을 느낍니다."</p>
            </div>
            <div className="subs">
              <h2>학습과 성장</h2>
              <p>"지식에는 끝이 없습니다. 소프트웨어 공학을 전공하며 쌓은 이론적 기반 위에, 실무 경험을 더해왔습니다. 새로운 기술을 배우고 최신 트렌드를 따라잡는 것은 저의 즐거움입니다."</p>
            </div>
            <div className="subs">
              <h2>전문성과 혁신</h2>
              <p>"CCTV 실시간 객체 탐지, 카리프트 알고리즘 개발, ERP 서비스 구축 등 다양한 프로젝트를 통해 전문성과 문제 해결 능력을 키워왔습니다. 각 프로젝트는 저에게 귀중한 배움의 기회였습니다."</p>
            </div>
            <div className="subs">
              <h2>팀워크와 협력</h2>
              <p>"혼자서는 빠르게 갈 수 있지만, 함께라면 더 멀리 갈 수 있습니다. 다양한 사람들과의 협업을 통해 더 나은 결과를 추구하며, 팀워크와 소통을 중시합니다."</p>
            </div>
            <div className="subs">
              <h2>미래 지향적 비전</h2>
              <p>"기술은 미래를 만듭니다. 저는 기술이 사람들의 삶을 어떻게 변화시킬 수 있는지 깊이 고민하며, 긍정적인 영향을 미치는 코드를 작성하고자 합니다. 지속 가능한 혁신을 통해 더 나은 미래를 만들어가겠습니다."</p>
            </div>
            <p className="start">이제 <span className="highlight">시작</span>합니다.</p>
          </div>
          <span className="material-symbols-outlined icon2">
            keyboard_double_arrow_down
          </span>
        </div>
      )}
      {!isTest001Visible && (
        <div className="Main-Wrap">

          <div className="top">
            <div className="bgimg"></div>

          </div>
          <div className="bar">
            <h3 className="ItemTitle">My Skills</h3><br />
            <BarChart />
          </div>
          <div className="Items_Wrap">

            <div className="Introduce">
              <div className="flx">
                <h3 className="ItemTitle">자기소개</h3>
              </div>
              <div className="ItemContents">

                안녕하세요, 김선익입니다.<br />
                저는 어릴 때부터 컴퓨터와 소프트웨어 개발에 관심을 가지고 성장했으며,<br />
                대학에서 소프트웨어 공학을 전공했습니다.<br />
                졸업 후 사무직 경험과 전산응용 기계제도 기능사 자격증을 통해<br />
                엔지니어링 역량을 키웠고,<br />
                개발 팀으로 이직해 C, C++로 CCTV 실시간 객체 탐지와<br />
                카리프트 알고리즘을 개발했습니다.<br />
                React를 사용해 ERP 서비스를 개발 중이었으나,<br />
                프로젝트 완료 전에 퇴사하게 되었습니다.<br />
                다양한 경험을 통해 문제 해결 능력과 팀 협업 능력을 키웠고,<br />
                빠르게 변화하는 환경에 적응하며 기술을 향상시켰습니다.<br />
                새로운 도전을 즐기고 혁신적인 프로젝트를 주도하고자 합니다.<br />
                감사합니다!
              </div>
            </div>
            <div className="ItemGroup">
              <div className="Item">
                <div className="flx">
                  <span className="material-symbols-outlined icon1">
                    workspace_premium
                  </span>
                  <h3 className="ItemTitle">자격증</h3>
                </div>
                <div className="Certifications-Wrap">
                  <div className="Certifications">
                    <span className="certifi_name" title="전산응용기계제도 기능사">전산응용기계제도 기능사</span>
                    <span className="certifi_day">2022.04</span>
                  </div>
                  <div className="Certifications">
                    <span className="certifi_name" title="컴퓨터활용능력 2급">컴퓨터활용능력 2급</span>
                    <span className="certifi_day">2013.12</span>
                  </div>
                  <div className="Certifications">
                    <span className="certifi_name" title="워드프로세서">워드프로세서</span>
                    <span className="certifi_day">2013.12</span>
                  </div>
                  <div className="Certifications">
                    <span className="certifi_name" title="ITQ OA 마스터">ITQ OA 마스터</span>
                    <span className="certifi_day">2013.12</span>
                  </div>
                  <div className="Certifications">
                    <span className="certifi_name" title="1종보통 운전면허">1종보통 운전면허</span>
                    <span className="certifi_day">2013.12</span>
                  </div>
                </div>
              </div>
              <div className="Item">
                <div className="flx">
                  <span className="material-symbols-outlined icon1">code</span>
                  <h3 className="ItemTitle">개발 경험</h3>
                </div>
                <span className="Certifications-Wrap">C언어
                  <div className="Certifications">CCTV를 활용한 실시간 객체 탐지</div>
                  <div className="Certifications">카리프트 알고리즘</div>
                  <div className="Certifications">React</div>
                  사내 ERP서비스</span>
              </div>
              <div className="Item">
                <div className="flx">
                  <span className="material-symbols-outlined icon1">lightbulb</span>
                  <h3 className="ItemTitle">개발 철학</h3>
                </div>
                <span className="Certifications-Wrap">
                  <div className="Certifications">1. 사용자 중심</div>
                  <div className="Certifications">2. 사용자의 편리함</div>
                  <div className="Certifications">3. 사용자의 직관성</div>
                </span>
              </div>
            </div>
            <div className="Link-Wrap">
              <Link to="/webgame" className="Item WithBtn">
                <h3 className="ItemTitle">웹 게임</h3>
                <span className="ItemContents">캔버스를 활용한 간단한 게임</span>
              </Link>
              <Link to="/weather" className="Item WithBtn">
                <h3 className="ItemTitle">날씨 정보</h3>
                <span className="ItemContents">openweather API를 활용한 기상 웹</span>
              </Link>
              <Link to="/pokemon" className="Item WithBtn">
                <h3 className="ItemTitle">포켓몬 도감</h3>
                <span className="ItemContents">포켓몬 API를 활용한 도감 웹</span>
              </Link>
              <Link to="/erp/login" className="Item WithBtn" target="_blank">
                <h3 className="ItemTitle">ERP 시스템</h3>
                <span className="ItemContents">사내에 필요한 다양한 기능을 제공하는 ERP</span>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
