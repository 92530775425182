import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../../firebase-login';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { SignUpModal } from './ERPsignup';
import { getDoc, doc } from 'firebase/firestore';
import '../../css/ERPLogin.css'
export const ErpLoginPage = () => {
  const [email, setEmail] = useState(''); // 사용자가 이메일 전체를 입력하는 부분
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const toggleSignUpModal = () => {
    setShowSignUpModal(!showSignUpModal);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Firestore에서 사용자의 추가 정보 조회
      const userDocRef = doc(db, "users", email);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        // 세션스토리지에 사용자 정보 저장
        sessionStorage.setItem('username', userData.fullName); // 사용자의 이름
        sessionStorage.setItem('companyName', userData.companyName); // 회사 이름 저장
        const fullEmail = `${userData.email}@${userData.domain}`;
        sessionStorage.setItem('email', fullEmail);//풀이메일
        sessionStorage.setItem('position', userData.position); // 사용자 직급
        sessionStorage.setItem('userId', user.uid); // 사용자 UID
        sessionStorage.setItem('department', userData.department); 
        sessionStorage.setItem('authToken', true); 

        navigate('/erp'); // 성공 후 대시보드로 이동
      } else {
        console.log("No such document!");
        setError("사용자 정보를 찾을 수 없습니다.");
      }
    } catch (error) {
      console.error("로그인 실패: ", error);
      setError(error.message);
    }
  };

  return (
    <>
      <div className='ERP_Login_Container'>
        <h2>ERP System Login</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <form onSubmit={handleLogin}>
          <div>
            <span>ID: </span>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder='rr@aaa.com'
              />
          </div>
          <div>
            <span>Password: </span>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder='123123'
            />
          </div>
          <button type="submit">Login</button>
        </form>
        <div className='sign'>
          회원가입을 원하시나요? 
          <button onClick={toggleSignUpModal}>회원가입</button>
          </div>
        {showSignUpModal && <SignUpModal onClose={toggleSignUpModal} />}
      </div>

    </>
  );
};
