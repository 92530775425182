import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../css/ERP.css';
import { GiHamburgerMenu } from 'react-icons/gi';

export const HeaderMenuERP = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [companyName, setCompanyName] = useState('');

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = (type) => {
        navigate(`/erp/${type}`);
        setMenuOpen(false);
    };

    const navigate = useNavigate();

    useEffect(() => {
        const name = sessionStorage.getItem('companyName');
        setCompanyName(name || '');  // 세션 스토리지에서 회사 이름을 가져와 상태에 저장
    }, []);

    return (
        <div className="Header" id='header'>
            <Link className="logo" to="/erp" onClick={() => setMenuOpen(false)}></Link>
            <Link className="company-name"  to="/erp" onClick={() => setMenuOpen(false)}>{companyName}</Link>
            {menuOpen && (
                <div className="topNav">
                    <div className='module' onClick={() => closeMenu('edms')}>전자결재</div>
                    <div className='module' onClick={() => closeMenu('schedule')}>일정</div>
                    <div className='module' onClick={() => closeMenu('board')}>게시판</div>
                    <div className='module' onClick={() => closeMenu('documentbox')}>업무문서함</div>
                </div>
            )}
            <GiHamburgerMenu onClick={toggleMenu} size="1.5em" className='hamberger' />
        </div>
    );
};
