import React, { useState, useEffect } from 'react';
import { storage } from '../../../firebaseConfig';
import { ref, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import '../../css/Documentbox.css';
import { HeaderMenuERP } from '../../components/HeaderMenu_ERP';

export const Documentbox = () => {
  const [fileToUpload, setFileToUpload] = useState(null);
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [currentPath, setCurrentPath] = useState('documents/');
  const [newFolderName, setNewFolderName] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/erp/login');
      } else {
        initializeAndFetchFolders(); // 로그인된 사용자에 대해 폴더 초기화 및 목록을 불러옵니다.
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  useEffect(() => {
    fetchFoldersAndFiles();
  }, [currentPath]);

  const initializeAndFetchFolders = async () => {
    const userId = sessionStorage.getItem('userId'); // 세션 스토리지에서 userId 가져오기
    const userDomain = auth.currentUser.email.split('@')[1];
    const department = sessionStorage.getItem('department'); // 세션 스토리지에서 userId 가져오기

    // 개인 폴더 경로
    const personalFolderPath = `documents/${userId}/`;
    const companyFolderPath = `documents/${userDomain}/`;
    const departmentFolderPath = `documents/${userDomain}_${department}/`; // 도메인과 부서명 결합

    // 개인 폴더가 있는지 확인하고, 없다면 생성
    const personalFolderRef = ref(storage, `${personalFolderPath}_dummy`);
    getDownloadURL(personalFolderRef).catch(async () => {
      // 폴더가 존재하지 않으면, 더미 파일을 사용하여 폴더 생성
      await uploadBytes(personalFolderRef, new Blob(['Dummy']));
      console.log('개인용 폴더 생성됨');
    })
    const companyFolderRef = ref(storage, `${companyFolderPath}_dummy`);
    getDownloadURL(companyFolderRef).catch(async () => {
      // 폴더가 존재하지 않으면, 더미 파일을 사용하여 폴더 생성
      await uploadBytes(companyFolderRef, new Blob(['Dummy']));
      console.log('전체 폴더 생성됨');
    })
    const departmentFolderRef = ref(storage, `${departmentFolderPath}_dummy`);
    getDownloadURL(departmentFolderRef).catch(async () => {
      // 폴더가 존재하지 않으면, 더미 파일을 사용하여 폴더 생성
      await uploadBytes(departmentFolderRef, new Blob(['Dummy']));
      console.log('부서용 폴더 생성됨');
    });

    // 폴더 및 파일 목록 불러오기
    fetchFoldersAndFiles();
  };

  const fetchFoldersAndFiles = async () => {
    const storageRef = ref(storage, currentPath);
    const currentUserId = sessionStorage.getItem('userId'); // 현재 사용자의 userId
    const currentDomain = sessionStorage.getItem('email').split('@')[1];
    const currentDepartment = sessionStorage.getItem('department'); // 세션 스토리지에서 userId 가져오기

    try {
      const result = await listAll(storageRef);
      const foldersData = result.prefixes.map(prefix => ({
        id: prefix.name,
        // 기본적으로 id 값을 name으로 설정
        name: prefix.name,
      }));

      let updatedFolders;

      if (currentPath === 'documents/') {
        // documents 경로인 경우에만 필터링 적용
        updatedFolders = foldersData.map(folder => {
          if (folder.id === currentUserId) {
            return { ...folder, name: '개인용 폴더' }; // 개인용 폴더 이름 변경
          } else if (folder.id === currentDomain) {
            return { ...folder, name: '전체 폴더' }; // 전체 폴더 이름 변경
          } else if (folder.id === `${currentDomain}_${currentDepartment}`) {
            return { ...folder, name: `${currentDepartment} 부서 폴더` }; // 부서 폴더 이름 변경
          }
          return folder; // 그 외 폴더는 변경 없음
        }).filter(folder => {
          // 현재 사용자에게만 개인용 폴더를 표시하고, 나머지는 전체 및 부서 폴더만 표시
          return folder.name === '개인용 폴더' || folder.name.includes('부서') || folder.name === '전체 폴더';
        });
      } else {
        // 다른 경로인 경우에는 모든 폴더 표시
        updatedFolders = foldersData;
      }

      const filesWithUrls = (await Promise.all(
        result.items.map(async item => ({
          name: item.name,
          url: await getDownloadURL(item),
        }))
      )).filter(file => file.name !== '_dummy'); // 여기에서 _dummy 파일을 필터링합니다.

      setFolders(updatedFolders); // 업데이트된 폴더 목록으로 상태 업데이트
      setFiles(filesWithUrls); // 파일 목록 상태 업데이트
    } catch (error) {
      console.error("Error fetching files and folders: ", error);
    }
  };

  const handleFileChange = (e) => setFileToUpload(e.target.files[0]);

  const uploadFile = async () => {
    if (!fileToUpload) {
      alert('파일을 선택 후 업로드하세요.');
      return;
    }

    const fileRef = ref(storage, `${currentPath}${fileToUpload.name}`);
    await uploadBytes(fileRef, fileToUpload).then(() => {
      alert('파일 업로드 완료');
      fetchFoldersAndFiles(); // 새로운 파일 목록을 가져옵니다.
    });
    setFileToUpload(null);
  };

  const createFolder = async () => {
    if (!newFolderName.trim()) {
      alert('폴더 이름을 입력하세요.');
      return;
    }

    const dummyFilePath = `${currentPath}${newFolderName.trim()}/_dummy`;
    const dummyFileRef = ref(storage, dummyFilePath);
    await uploadBytes(dummyFileRef, new Blob([])).then(() => {
      alert('폴더 생성 완료');
      fetchFoldersAndFiles(); // 새로운 폴더 목록을 가져옵니다.
    });
    setNewFolderName('');
  };

  // 파일 삭제 기능
  const deleteFile = async (fileName) => {
    const fileRef = ref(storage, `${currentPath}${fileName}`);
    await deleteObject(fileRef).then(() => {
      alert('파일 삭제 완료');
      fetchFoldersAndFiles(); // 파일 목록을 새로고침합니다.
    });
  };

  // 폴더 삭제 기능
  const deleteFolder = async (folderName) => {
    // 폴더 내 모든 파일을 삭제하는 로직을 구현해야 합니다.
    // 여기서는 단순화를 위해 더미 파일만 삭제합니다.
    const dummyFilePath = `${currentPath}${folderName}/_dummy`;
    const dummyFileRef = ref(storage, dummyFilePath);
    await deleteObject(dummyFileRef).then(() => {
      alert('폴더 삭제 완료');
      fetchFoldersAndFiles(); // 폴더 목록을 새로고침합니다.
    });
  };

  const navigateToFolder = (newPath) => {
    setCurrentPath(newPath);
  };

  const renderPathSegments = () => {
    const segments = currentPath.split('/').filter(segment => segment);
    let cumulativePath = '';

    return segments.map((segment, index) => {
      cumulativePath += `${segment}/`;
      let adjustedPath = cumulativePath;
      let displayName = segment;

      // '개인용 폴더'와 '전체 폴더'를 각각 '개인'과 '전체'로 표시
      if (segment === sessionStorage.getItem('userId')) {
        displayName = '개인';
      } else if (segment === sessionStorage.getItem('email').split('@')[1]) {
        displayName = '전체';
      } else if (segment === `${sessionStorage.getItem('email').split('@')[1]}_${sessionStorage.getItem('department')}`) {
        displayName = `${sessionStorage.getItem('department')} 부서`;
      }

      return (
        <span key={index}>
          <a href="#!" onClick={(e) => {
            e.preventDefault();
            navigateToFolder(adjustedPath);
          }}>
            {displayName}
          </a>
          {index < segments.length - 1 ? ' / ' : ''}
        </span>
      );
    });
  };

  const currentDepartment = sessionStorage.getItem('department');

  return (
    <div className='ERP-Wrap'>
      <HeaderMenuERP />
      <div className="document-box">
        <div className='locationWrap'>
          <h3>현재 경로: {renderPathSegments()}</h3>

          <h4>폴더:</h4>
          <ul>
            {folders.map((folder, index) => (
              <li key={index}>
                <span onClick={() => navigateToFolder(`${currentPath}${folder.id}/`)}>
                  {folder.name} {/* 변경된 이름 또는 기존 이름을 보여줌 */}
                </span>
                {/* "개인용 폴더", "전체 폴더", 현재 부서의 폴더에 대한 삭제 버튼 숨김 처리 */}
                {(folder.name !== '개인용 폴더' && folder.name !== '전체 폴더' && !folder.name.includes(`${currentDepartment} 부서 폴더`)) && (
                  <button onClick={() => deleteFolder(folder.id)}>삭제</button>
                )}
              </li>
            ))}
          </ul>
          <div>
            <h4>파일:</h4>
            <ul>
              {files.map((file, index) => (
                <li key={index}>
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    {file.name}
                  </a>
                  <button onClick={() => deleteFile(file.name)}>삭제</button>
                </li>
              ))}
            </ul>
          </div>
        </div>

<div className='addWrap'>
  <h2>업무문서함</h2>
          <div className='fileWrap'>
            <input type="file" onChange={handleFileChange} />
            <button onClick={uploadFile}>파일 업로드</button>
          </div>
          <div className='folderWrap'>
            <input
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="새 폴더 이름"
            />
            <button onClick={createFolder}>폴더 생성</button>
          </div>
</div>

      </div>
    </div>
  );
};