import React, { useState, useEffect } from 'react';
import SeatSelectionModal from './SeatSelectionModal';
import '../css/ReservationModal.css';
import { db } from '../../firebaseConfig';
import { collection, addDoc, updateDoc, doc, getDoc } from 'firebase/firestore';

function ReservationModal({ performance, user, onClose }) {
  const [uniqueLocations, setUniqueLocations] = useState([]);
  const [reservationDetails, setReservationDetails] = useState({
    scheduleIndex: null,
    people: 1,
    seats: [],
    location: ''
  });
  const [showSeatSelectionModal, setShowSeatSelectionModal] = useState(false);
  const [performanceData, setPerformanceData] = useState(performance);

  useEffect(() => {
    const locations = new Set(performance.schedules.map(s => s.location));
    setUniqueLocations([...locations]);
  }, [performance.schedules]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReservationDetails({ ...reservationDetails, [name]: value });
  };

  const refreshPerformanceData = async () => {
    try {
      const docRef = doc(db, 'performances', performance.id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setPerformanceData(docSnap.data());
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  const isExhibition = () => performance.category === 'exhibition';

  const handleOpenSeatSelection = async () => {
    if (!isExhibition() && reservationDetails.scheduleIndex != null) {
      await refreshPerformanceData();
      setShowSeatSelectionModal(true);
    } else if (!isExhibition()) {
      alert("날짜와 위치를 먼저 선택해주세요.");
    }
  };

  const handleSeatSelection = (selectedSeats) => {
    setReservationDetails({ ...reservationDetails, seats: selectedSeats });
    setShowSeatSelectionModal(false);
  };

  const handleSubmit = async () => {
    if (!user) {
      alert("로그인이 필요합니다.");
      return;
    }
  
    if (reservationDetails.seats.length === 0) {
      alert("좌석을 선택해주세요.");
      return;
    }
  
    try {
      await addDoc(collection(db, 'reservations'), {
        performanceId: performance.id,
        seats: reservationDetails.seats,
        userId: user.uid
      });
  
      if (!isExhibition()) {
        const performanceDocRef = doc(db, 'performances', performance.id);
        const performanceDocSnap = await getDoc(performanceDocRef);
  
        if (performanceDocSnap.exists()) {
          const updatedPerformance = performanceDocSnap.data();
          const selectedSchedule = updatedPerformance.schedules[reservationDetails.scheduleIndex];
  
          const updatedSeats = selectedSchedule.seats.map(seat => {
            if (reservationDetails.seats.includes(seat.number)) {
              return { ...seat, status: 'reserved' };
            } else {
              return seat;
            }
          });
  
          updatedPerformance.schedules[reservationDetails.scheduleIndex].seats = updatedSeats;
  
          await updateDoc(performanceDocRef, {
            schedules: updatedPerformance.schedules
          });
        }
      }
  
      await refreshPerformanceData();
  
      alert('예약이 완료되었습니다.');
      onClose();
    } catch (error) {
      console.error('Error saving reservation:', error);
      alert('예약이 실패했습니다.');
    }
  };

  const handleBackgroundClick = () => {
    if (!showSeatSelectionModal) {
      onClose();
    }
  };

  return (
    <div className="reservation-modal-background" onClick={handleBackgroundClick}>
      <div className="reservation-modal-content" onClick={e => e.stopPropagation()}>
        <div className='modalwrap'>
          <div className='optiontitle'>위치:</div>
          <select className='items' name="location" value={reservationDetails.location} onChange={handleInputChange}>
            <option value="">위치 선택</option>
            {uniqueLocations.map((location, index) => (
              <option key={index} value={location}>{location}</option>
            ))}
          </select>
        </div>
        <div className='modalwrap'>
          <div className='optiontitle'>날짜:</div>
          <select className='items' name="scheduleIndex" value={reservationDetails.scheduleIndex} onChange={handleInputChange} disabled={!reservationDetails.location || isExhibition()}>
            <option value="">날짜 선택</option>
            {performanceData.schedules.filter(s => s.location === reservationDetails.location).map((schedule, index) => (
         <option key={index} value={index}>{schedule.date.replace('T', ' ')}</option>
            ))}
          </select>
        </div>
        <div className='modalwrap'>
          <div className='optiontitle'>인원:</div>
          <input className='items' type="number" name="people" value={reservationDetails.people} onChange={handleInputChange} min="1" />
        </div>
        <div className='btnwrap'>
          {!isExhibition() && (
            <button onClick={handleOpenSeatSelection} className='btn1'>좌석 선택</button>
          )}
          {reservationDetails.seats.length > 0 && !isExhibition() && (
            <div>
              <h3>선택된 좌석:</h3>
              <ul>
                {reservationDetails.seats.map(seat => (
                  <li key={seat}>{seat}</li>
                ))}
              </ul>
            </div>
          )}
          <button onClick={handleSubmit} className='btn1'>예약 확인</button>
          {!isExhibition() && showSeatSelectionModal && (
            <SeatSelectionModal
              schedule={performanceData.schedules[reservationDetails.scheduleIndex]}
              people={parseInt(reservationDetails.people, 10)}
              onSeatSelect={handleSeatSelection}
              onClose={() => setShowSeatSelectionModal(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ReservationModal;
