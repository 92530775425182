import './App.css';
import { Main } from './views/pages/Main';
import { ToDoList } from './views/pages/ToDoList';
import { Netflix } from './views/pages/Netflix';
import { Weather } from './views/pages/Weather';
import { Webgame } from './views/pages/WebGame';
import { NotFound } from './views/pages/NotFound';
import { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Dashboard } from './views/pages/DashBoard';
import { Ticket } from './views/pages/Ticket';
import { Login } from './views/pages/Login';
// import ProtectedRoute from './views/components/ProtectedRoute';
import Footer from './views/components/Footer';
import HeaderMenu from './views/components/HeaderMenu';
import { ClassicPage } from './views/pages/TicClassicPage';
import { ConcertPage } from './views/pages/TicConcertPage';
import { ExhibitionPage } from './views/pages/TicExhibitionPage';
import { MusicalPage } from './views/pages/TicMusicalPage';
import { PlayPage } from './views/pages/TicPlayPage';
import { SportsPage } from './views/pages/TicSportsPage';
import { TicMaster } from './views/pages/TicMaster';
import { ChartMaker } from './views/pages/ChartMaker';
import { Pokemon } from './views/pages/Pokemon';
import { UserInfo } from './views/components/UserInfo';
import { ERP } from './views/pages/ERP/ERP';
import { Mail } from './views/pages/ERP/Mail';
import { Am } from './views/pages/ERP/AM';
import { Board } from './views/pages/ERP/Board';
import { Contacts } from './views/pages/ERP/Contacts';
import { Deals } from './views/pages/ERP/Deals';
import { Documentbox } from './views/pages/ERP/Documentbox';
import { EDMS } from './views/pages/ERP/EDMS';
import { Forms } from './views/pages/ERP/Forms';
import { Government_Support } from './views/pages/ERP/Government-Support';
import { HRM } from './views/pages/ERP/HRM';
import { Ladder } from './views/pages/ERP/Ladder';
import { Messenger } from './views/pages/ERP/Messenger';
import { Quick_Parcel } from './views/pages/ERP/Quick-Parcel';
import { Schedule } from './views/pages/ERP/Schedule';
import { Welfare_Mall } from './views/pages/ERP/Welfare-Mall';
import { Notes } from './views/pages/ERP/Notes';
import { Legal_Education } from './views/pages/ERP/Legal-Education';
import { ErpLoginPage } from './views/pages/ERP/ERP_LoginPage';

function App() {

  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedMode, setSelectedMode] = useState('darkmode');
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const location = useLocation();

  const Mod_Change = () => {
    setMenuOpen(!menuOpen); // 현재 상태의 반대로 변경
  }

  const selectMode = (mode) => {
    setSelectedMode(mode);
    document.documentElement.classList.remove('darkmode', 'lightmode');
    document.documentElement.classList.add(mode);
  };

  useEffect(() => {
    const closeMenuOnOutsideClick = (e) => {
      if (!menuOpen) return;
      if (e.target.closest('.Mod_Btn_Wrap')) return;
      setMenuOpen(false);
    };

    document.addEventListener('click', closeMenuOnOutsideClick);
    return () => {
      document.removeEventListener('click', closeMenuOnOutsideClick);
    };
  }, [menuOpen]);

  const scrollToHeader = () => {
    document.getElementById("header").scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <>
      <HeaderMenu selectedMode={selectedMode} isVisible={isHeaderVisible} />
      <div className={`App ${location.pathname === '/erp' ? 'light' : ''} ${selectedMode === 'darkmode' ? 'darkmode' : 'lightmode'}`}>
        <Routes>
          <Route path="/" element={<Main setIsHeaderVisible={setIsHeaderVisible} />} />
          <Route path="/login" element={<Login />} />
          <Route path="todolist" element={<ToDoList />} />
          <Route path="ticket" element={<Ticket />} />
          <Route path="ticket/booking/classic" element={<ClassicPage />} />
          <Route path="ticket/booking/concert" element={<ConcertPage />} />
          <Route path="ticket/booking/exhibition" element={<ExhibitionPage />} />
          <Route path="ticket/booking/musical" element={<MusicalPage />} />
          <Route path="ticket/booking/play" element={<PlayPage />} />
          <Route path="ticket/booking/sports" element={<SportsPage />} />
          <Route path="ticket/booking/master" element={<TicMaster />} />
          <Route path="userinfo" element={<UserInfo />} />
          <Route path="webgame" element={<Webgame />} />
          <Route path="weather" element={<Weather />} />
          <Route path="chartmaker" element={<ChartMaker />} />
          <Route path="pokemon" element={<Pokemon />} />

          <Route path="erp/login" element={<ErpLoginPage />} />
          <Route path="erp" element={<ERP />} />
          <Route path="erp/mail" element={<Mail />} />
          <Route path="erp/accounting_management" element={<Am />} />
          <Route path="erp/board" element={<Board />} />
          <Route path="erp/contacts" element={<Contacts />} />
          <Route path="erp/deals" element={<Deals />} />
          <Route path="erp/documentbox" element={<Documentbox />} />
          <Route path="erp/edms" element={<EDMS />} />
          <Route path="erp/forms" element={<Forms />} />
          <Route path="erp/government_support" element={<Government_Support />} />
          <Route path="erp/human_resources_management" element={<HRM />} />
          <Route path="erp/ladder" element={<Ladder />} />
          <Route path="erp/messenger" element={<Messenger />} />
          <Route path="erp/quick_parcel" element={<Quick_Parcel />} />
          <Route path="erp/notes" element={<Notes />} />
          <Route path="erp/schedule" element={<Schedule />} />
          <Route path="erp/welfare_mall" element={<Welfare_Mall />} />
          <Route path="erp/legal_education" element={<Legal_Education />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <Footer selectedMode={selectedMode} />
      </div>
      <div className="Mod_Btn_Wrap">
        <button className="material-symbols-outlined" onClick={scrollToHeader}>vertical_align_top</button>
        <button className="material-symbols-outlined" onClick={Mod_Change}>settings</button>
        {menuOpen && (
          <div className={`Mod_Menu ${selectedMode === 'darkmode' ? 'darkmode' : 'lightmode'}`}>
            <div>화면 스타일</div>
            <div className="mod-wrap">
              <div className={`modimg-wrap ${selectedMode === 'lightmode' ? 'selected' : ''}`} onClick={() => selectMode('lightmode')}>
                <div className="modimg">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <span>라이트모드</span>
              </div>
              <div className={`modimg-wrap ${selectedMode === 'darkmode' ? 'selected' : ''}`} onClick={() => selectMode('darkmode')}>
                <div className="modimg">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <span>다크모드</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default App;
