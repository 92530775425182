import { useState, useEffect } from 'react';
import { db } from '../../../firebaseConfig';
import { collection, query, where, getDocs, addDoc, onSnapshot, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import { HeaderMenuERP } from '../../components/HeaderMenu_ERP';
import '../../css/ERPNotes.css'

export const Notes = () => {
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [expandedMessageIds, setExpandedMessageIds] = useState(new Set());
  const [sentMessages, setSentMessages] = useState([]);
  const [visibleSentMsg, setVisibleSentMsg] = useState({});
  const [userMap, setUserMap] = useState({});
  const location = useLocation();

  useEffect(() => {
    const fetchUserOptions = async () => {
      const storedEmail = sessionStorage.getItem('email');
      const domain = storedEmail?.split('@')[1];
      if (!domain) {
        console.error('No domain found');
        return;
      }
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('domain', '==', domain));
      try {
        const querySnapshot = await getDocs(q);
        const currentUserEmail = sessionStorage.getItem('email');
        const userOptions = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            name: doc.data().fullName,
            email: doc.data().email,
            department: doc.data().department,
            position: doc.data().position,
            domain: doc.data().domain
          }))
          .filter(user => user.email !== currentUserEmail);
        const userMap = {};
        querySnapshot.forEach(doc => {
          userMap[doc.data().email] = doc.data().fullName;
        });
        setUserOptions(userOptions);
        setUserMap(userMap);

        const state = location.state;
        if (state && state.user) {
          const selectedUser = userOptions.find(user => user.email === state.user);
          if (selectedUser) {
            setSelectedUserId(selectedUser.id);
          }
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUserOptions();
  }, [location.state]);

  const sendMessage = async () => {
    if (!selectedUserId || !message) {
      alert('Please select a user and write a message.');
      return;
    }
    const recipient = userOptions.find(user => user.id === selectedUserId);
    if (!recipient) {
      alert('Recipient not found');
      return;
    }

    const recipientEmail = `${recipient.email}@${recipient.domain}`;

    try {
      await addDoc(collection(db, 'notes'), {
        to: recipientEmail,
        from: sessionStorage.getItem('email'),
        name: sessionStorage.getItem('username'),
        title: title,
        content: message,
        timestamp: new Date(),
        read: "안읽음"
      });

      const recipientDocRef = doc(db, 'users', recipient.id);
      const recipientDoc = await getDoc(recipientDocRef);
      if (recipientDoc.exists()) {
        const notifications = recipientDoc.data().notifications || {};
        notifications.notes = (notifications.notes || 0) + 1;
        await updateDoc(recipientDocRef, { notifications });
      }

      setMessage('');
      alert('쪽지가 성공적으로 발송되었습니다.');
      setTitle('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  useEffect(() => {
    const currentUserEmail = sessionStorage.getItem('email');
    if (!currentUserEmail) {
      console.error('User email is not available');
      return;
    }

    const messagesRef = collection(db, 'notes');
    const q = query(messagesRef, where('to', '==', currentUserEmail));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const receivedMessages = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setReceivedMessages(receivedMessages);
    }, (error) => {
      console.error('Error fetching received messages:', error);
    });

    const fetchSentMessages = async () => {
      const currentUserEmail = sessionStorage.getItem('email');
      const messagesRef = collection(db, 'notes');
      const q = query(messagesRef, where('from', '==', currentUserEmail));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const sentMessages = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSentMessages(sentMessages);
      }, (error) => {
        console.error('Error fetching sent messages:', error);
      });
      return () => unsubscribe();
    };

    fetchSentMessages();
    return () => unsubscribe();
  }, []);

  const toggleMessage = async (msgId) => {
    setExpandedMessageIds(prev => {
      const newSet = new Set(prev);
      if (newSet.has(msgId)) {
        newSet.delete(msgId);
      } else {
        newSet.add(msgId);
      }
      return newSet;
    });

    if (!expandedMessageIds.has(msgId)) {
      const messageRef = doc(db, 'notes', msgId);
      await updateDoc(messageRef, {
        read: "읽음"
      });

      const currentUserEmail = sessionStorage.getItem('email');
      const currentUserDomain = currentUserEmail.split('@')[1];
      const currentUserDocRef = doc(db, 'users', `${currentUserEmail.split('@')[0]}@${currentUserDomain}`);
      const currentUserDoc = await getDoc(currentUserDocRef);
      if (currentUserDoc.exists()) {
        const notifications = currentUserDoc.data().notifications || {};
        notifications.notes = Math.max((notifications.notes || 0) - 1, 0);
        await updateDoc(currentUserDocRef, { notifications });
      }
    }
  };

  const toggleSentMsgDetail = (msgId) => {
    setVisibleSentMsg((prevStates) => ({
      ...prevStates,
      [msgId]: !prevStates[msgId]
    }));
  };

  return (
    <div className="ERP-Wrap">
      <HeaderMenuERP />
      <div className="notes-container">
        <h2>쪽지 보내기</h2>
        <div className="message-form">
          <select id="userSelect" value={selectedUserId} onChange={e => setSelectedUserId(e.target.value)}>
            <option value="">보낼 사람을 선택하세요</option>
            {userOptions.map(user => (
              <option key={user.id} value={user.id}>
                {user.department} {user.position} {user.name}
              </option>
            ))}
          </select>
          <input
            placeholder="제목을 입력하세요."
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          <textarea
            placeholder="내용을 입력하세요."
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          <button onClick={sendMessage}>메시지 보내기</button>
        </div>

        <div className='recevedMSG'>
          <h2>받은 메시지</h2>
          {receivedMessages.map(msg => (
            <div key={msg.id} className="message-item">
              <div className='flx' onClick={() => toggleMessage(msg.id)} style={{ cursor: 'pointer', fontWeight: msg.read === "읽음" ? 'normal' : 'bold' }}>
                <h3>{msg.title}</h3>
                <p>발신자 : {msg.name}</p>
              </div>
              {expandedMessageIds.has(msg.id) && (
                <div className="message-content">
                  <p>{msg.content}</p>
                </div>
              )}
            </div>
          ))}
        </div>
<div className='sendedMSG'>
  
          <h2>보낸 메시지</h2>
          {sentMessages.map(msg => (
            <div key={msg.id} className="message-item" onClick={() => toggleSentMsgDetail(msg.id)}>
              <div className='flx'>
                <h3>{msg.title || "제목 없음"}</h3>
                <p>수신자: {userMap[msg.to.split('@')[0]] || msg.to}</p>
                <p> {msg.read}</p>
              </div>
              {visibleSentMsg[msg.id] && (
                <div className="message-content">
                  <p>{msg.content}</p>
                </div>
              )}
            </div>
          ))}
</div>
      </div>
    </div>
  );
};
