import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyB8HGEEpm3dOV3ngaxkD0LULKoq5q8dwO4",
    authDomain: "pofol001-5ed52.firebaseapp.com",
    projectId: "pofol001-5ed52",
    storageBucket: "pofol001-5ed52.appspot.com",
    messagingSenderId: "705217139095",
    appId: "1:705217139095:web:b780f576516b5b30bd34d2",
    measurementId: "G-V80K8RLFWB"
};
const app = initializeApp(firebaseConfig);
// Firestore 인스턴스 가져오기
export const db = getFirestore(app);
export const storage= getStorage(app)